<template>
  <div class="px-5">
    <div class="hidden-sm-and-down">
      <v-row>
        <v-col cols="5">
          <div class="text-h4 primary--text">รายงานสรุปยอดค้างชำระ</div>
        </v-col>
        <v-col cols="5">
          <!-- <div class="d-flex mt-5">
          
          <div class="ml-0" style="width: 200px;">
          <v-select label="เลือกช่วงวันที่" :items="['a','b']" outlined dense hide-details/>
          </div>

          <div style="width: 200px;" class="mx-2">
          <v-text-field label="วันที่" prepend-inner-icon="mdi-calendar" outlined rounded dense hide-details></v-text-field>
          </div>

          <div style="width: 200px;" class="mx-10">
          <v-text-field label="ถึงวันที่" prepend-inner-icon="mdi-calendar" outlined rounded dense hide-details></v-text-field>
          </div>

        </div> -->
        </v-col>
        <v-col cols="2" class="text-right">
          <v-btn
            color="success"
            small
            outlined
            class="ml-5 px-10"
            @click="download_excel()"
            >ดาวน์โหลด <v-icon right small>mdi-download</v-icon></v-btn
          >
        </v-col>
      </v-row>

      <v-toolbar dense flat rounded outlined class="my-5 pt-3 pb-15">
        <v-row>
          <v-col cols="2">
            <v-select
              label="สถานะ"
              v-model="filter.status"
              :items="status"
              item-value="value"
              item-text="text"
              outlined
              rounded
              dense
              hide-details
              @change="getData(true)"
            >
            </v-select>
          </v-col>
          <v-col cols="2">
            <v-select
              label="ช่วงเวลา"
              v-model="timeRangeType"
              :items="timeRangeTypes"
              item-value="value"
              item-text="text"
              outlined
              rounded
              dense
              hide-details
            >
            </v-select>
          </v-col>

          <v-col cols="2">
            <DatePicker
              v-if="timeRangeType == 1"
              label="วันจดทะเบียน"
              :value="filter.start_date"
              @onChange="(val) => (filter.start_date = val)"
              hideDetails
              outlined
              rounded
              dense
            />

            <v-menu
              v-else-if="timeRangeType == 2"
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="filter.start_date ? getMonth(filter.start_date) : ''"
                  label="เดือน"
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  hideDetails
                  outlined
                  rounded
                  dense
                  readonly
                >
                </v-text-field>
              </template>
              <v-date-picker
                class="hidden-sm-and-down"
                v-model="month"
                type="month"
                no-title
                scrollable
                locale="th"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                <v-btn text color="primary" @click="onSaveMonth(month)"
                  >OK</v-btn
                >
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="2">
            <DatePicker
              v-if="timeRangeType == 1"
              label="ถึงวันที่"
              :value="filter.end_date"
              @onChange="(val) => (filter.end_date = val)"
              hideDetails
              outlined
              rounded
              dense
            />
          </v-col>

          <v-col cols="2"></v-col>

          <v-col cols="2">
            <v-btn color="primary" small class="float-right" @click="getData()"
              >แสดงรายงาน <v-icon right>mdi-magnify</v-icon></v-btn
            >
          </v-col>
        </v-row>
      </v-toolbar>
    </div>
    <div class="hidden-md-and-up">
      <v-row>
        <div class="text-h6 primary--text">รายงานสรุปยอดค้างชำระ</div>
      </v-row>
      <v-row justify="end" class="mb-3">
        <v-btn color="success" small outlined @click="download_excel()"
          >ดาวน์โหลด <v-icon right small>mdi-download</v-icon></v-btn
        >
      </v-row>
      <v-card outlined class="mb-5">
        <v-card-text>
          <v-row>
            <v-col cols="6" class="px-1">
              <v-select
                label="สถานะ"
                v-model="filter.status"
                :items="status"
                item-value="value"
                item-text="text"
                outlined
                rounded
                dense
                hide-details
                @change="getData(true)"
              >
              </v-select>
            </v-col>
            <v-col cols="6" class="px-1">
              <v-select
                label="ช่วงเวลา"
                v-model="timeRangeType"
                :items="timeRangeTypes"
                item-value="value"
                item-text="text"
                outlined
                rounded
                dense
                hide-details
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row v-if="timeRangeType == 1">
            <v-col cols="6" class="px-0">
              <DatePicker
                label="วันจดทะเบียน"
                :value="filter.start_date"
                @onChange="(val) => (filter.start_date = val)"
                hideDetails
                outlined
                rounded
                dense
            /></v-col>
            <v-col cols="6" class="px-0">
              <DatePicker
                label="ถึงวันที่"
                :value="filter.end_date"
                @onChange="(val) => (filter.end_date = val)"
                hideDetails
                outlined
                rounded
                dense
              />
            </v-col>
          </v-row>
          <v-row v-if="timeRangeType == 2">
            <v-col cols="6" class="px-1">
              <v-menu
                ref="menu2"
                v-model="menu2"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="
                      filter.start_date ? getMonth(filter.start_date) : ''
                    "
                    label="เดือน"
                    prepend-inner-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    hideDetails
                    outlined
                    rounded
                    dense
                    readonly
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  class="hidden-md-and-up"
                  v-model="month"
                  type="month"
                  no-title
                  scrollable
                  locale="th"
                  @change="onSaveMonth(month)"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-right pb-3">
              <v-btn color="primary" small @click="getData()"
                >แสดงรายงาน <v-icon right>mdi-magnify</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
    <v-data-table
      class="elevation-2 row-pointer"
      :headers="headers"
      :items="list"
      :search="search"
      :loading="loading"
      :loading-text="$table_loading_text"
      :page.sync="page"
      :items-per-page="filter.item_per_page"
      hide-default-footer
      item-key="_id"
    >
      <template v-slot:[`item._id`]="{ item }">
        {{
          list
            .map(function (x) {
              return x._id;
            })
            .indexOf(item._id) +
          1 +
          filter.item_per_page * (filter.page - 1)
        }}
      </template>
      <template v-slot:[`item.date_of_issue`]="{ item }">
        {{ formatDate(item.date_of_issue) }}
      </template>
      <template v-slot:[`item.inspect_price`]="{ item }">
        {{ item.inspect_price | numeral('0,0.00') }}
      </template>
      <template v-slot:[`item.third_insurance_price`]="{ item }">
        {{ item.third_insurance_price | numeral('0,0.00') }}
      </template>
      <template v-slot:[`item.tax_price`]="{ item }">
        {{ item.tax_price | numeral('0,0.00') }}
      </template>
      <template v-slot:[`item.insurance_price`]="{ item }">
        {{ item.insurance_price | numeral('0,0.00') }}
      </template>
      <template v-slot:[`item.other_product_price`]="{ item }">
        {{ item.other_product_price | numeral('0,0.00') }}
      </template>
      <template v-slot:[`item.net_price`]="{ item }">
        {{ item.net_price | numeral('0,0.00') }}
      </template>
      <template v-slot:[`item.payment_type`]="{ item }">
        {{ item.payment_type == 1 ? 'เงินสด' : '' }}
        {{ item.payment_type == 2 ? 'โอน' : '' }}
        {{ item.payment_type == 3 ? 'บัตรเครดิต' : '' }}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        {{ item.status == 0 ? 'รอดำเนินการ' : '' }}
        {{ item.status == 1 ? 'เปิดเบิลแล้ว' : '' }}
        {{ item.status == 2 ? 'ยกเลิก' : '' }}
      </template>
      <template v-slot:[`item.customer_phone_number`]="{ item }">
          {{ phoneNumberFormat(item.customer_phone_number) }}
      </template>
      <template v-slot:footer>
        <div class="hidden-sm-and-down">
          <table-pagination-custom
            :page="filter.page"
            :itemsPerPage="filter.item_per_page"
            :length="pageCount"
            @change-page="onChangePage"
            @change-items-per-page="onChangeitemsPerPage"
          />
        </div>
        <v-pagination
          class="hidden-md-and-up"
          v-model="filter.page"
          :length="pageCount"
          :total-visible="5"
          @input="onChangePage"
          circle
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DatePicker from '@/components/DatePicker.vue';
import * as moment from 'moment';
import JsonExcel from 'vue-json-excel';

export default {
  components: {
    DatePicker,
  },

  data: () => ({
    docTypeId: 0,
    search: '',
    selected: [],
    loading: true,
    page: 1,
    pageCount: 0,
    itemsPerPage: 50,
    showPhoneNumber: false,
    list: [],
    headers: [
      { text: '#', value: '_id', filterable: false, sortable: false, width: 0},
      { text: 'วันที่', value: 'date_of_issue'},
      { text: 'เลขที่เอกสาร', value: 'number', filterable: false },
      { text: 'ชื่อลูกค้า', value: 'customer_name' },
      { text: 'เบอร์โทร', value: 'customer_phone_number' },
      { text: 'ชื่อโปรเจ็ค', value: 'project_name', filterable: false },
      { text: 'ตรวจ', value: 'inspect_price', filterable: false },
      { text: 'พรบ', value: 'third_insurance_price', filterable: false },
      { text: 'ภาษี', value: 'tax_price', filterable: false },
      { text: 'ประกัน', value: 'insurance_price', filterable: false },
      { text: 'อื่น ๆ', value: 'other_product_price', filterable: false },
      { text: 'ยอดรวมสุทธิ', value: 'net_price', filterable: false },
      {
        text: 'สถานะ',
        value: 'status',
        sortable: false,
        filterable: false,
        align: 'center',
      },
    ],

    timeRangeType: 3,
    timeRangeTypes: [
      { value: 3, text: 'วันนี้' },
      { value: 0, text: 'เดือนปัจจุบัน' },
      { value: 2, text: 'เดือนก่อน' },
      { value: 1, text: 'เลือกช่วงวันที่' },
    ],
    status: [
      { text: 'ทั้งหมด', value: '' },
      { text: 'รอดำเนินการ', value: 0 },
      { text: 'เปิดบิลแล้ว', value: 1 },
      { text: 'ยกเลิก', value: 2 },
    ],
    menu: false,
    menu2: false,
    month: null,
    filter: {
      item_per_page: 10,
      page: 1,
      status: 0,
      branch_id: null,
      payment_type: null,
      start_date: moment().add(-0, 'days').format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
      // month: moment().format("YYYY-MM")
    },
  }),

  async created() {
    this.filter.document_type = this.docTypeId;
    this.filter.branch_id = await this.$store.state.selected_branch._id;
    await this.getData();
    await this.getShowPhoneNumber();
  },

  methods: {
    async getData() {
      this.loading = true;
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/report/invoice_report`, body)
        .then((res) => {
          this.list = res.result.data;
          this.pageCount = res.result.total_page || 1;
        })
        .catch((err) => {
          console.log('err', err);
          //this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    onChangePage(page) {
      this.filter.page = page;
      this.getData();
    },
    onChangeitemsPerPage(number) {
      this.filter.item_per_page = number;
      this.onChangePage(1);
    },
    formatDate(date) {
      if (!date) return '';

      let year = parseInt(moment(date).format('YYYY'));
      let m_d = moment(date).format('DD/MM');
      return `${m_d}/${year + 543}`;
    },
    async download_excel() {
      this.loading = true;
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios({
        method: 'post',
        url: `${this.$baseUrl}/report/download_invoice_report`,
        responseType: 'blob',
        data: body,
      })
        .then((response) => {
          if (
            moment(this.filter.start_date).format('DDMMYYYY') !=
            moment(this.filter.end_date).format('DDMMYYYY')
          ) {
            this.forceFileDownload(
              response,
              'ยอดค้างชำระ' +
                moment(this.filter.start_date, 'YYYY-MM-DDTHH:mm:ss').format(
                  'DD-MM-YYYY'
                ) +
                '_' +
                moment(this.filter.end_date, 'YYYY-MM-DDTHH:mm:ss').format(
                  'DD-MM-YYYY'
                ) +
                '.xlsx'
            );
          } else {
            this.forceFileDownload(
              response,
              'ยอดค้างชำระ' +
                moment(this.filter.start_date, 'YYYY-MM-DDTHH:mm:ss').format(
                  'DD-MM-YYYY'
                ) +
                '.xlsx'
            );
          }
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    forceFileDownload(response, title) {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', title);
      document.body.appendChild(link);
      link.click();
    },

    onSaveMonth(date) {
      this.$refs.menu.save(date);
      this.filter.start_date = moment(date, 'YYYY-MM')
        .startOf('month')
        .format('YYYY-MM-DD');
      this.filter.end_date = moment(date, 'YYYY-MM')
        .endOf('month')
        .format('YYYY-MM-DD');
    },
    getMonth(date) {
      return moment(date).format('YYYY-MM');
    },

    // download_excel(item){
    //     this.$router.push({
    //       name: "document-receipt-update",
    //       params: { id: item._id },
    //       query: { download: true },
    //     })
    // },
    edit(item) {
      this.$router.push({
        name: 'document-receipt-update',
        params: { id: item._id },
        // query: { download: true }
      });
    },
    async getShowPhoneNumber() {
      try {
        if (Object.keys(this.$store.state.group_permissions).length === 0) {
            await this.$store.dispatch('getGroupPermissions');
        };

        this.show_phone_number = this.$store.state.group_permissions.show_phone_number;
        if (this.show_phone_number == 0) {
          this.showPhoneNumber = false;
        } else {
          this.showPhoneNumber = true;
        };
      } catch (error) {
        this.showPhoneNumber = true;
        console.error('Error in getShowPhoneNumber:', error);
      };
    }, 
    phoneNumberFormat(val) {
      if (val) {
        if (!this.showPhoneNumber) return 'XXX-XXXXXXX';
        return val.substring(0, 3) + '-' + val.substring(3, val.length);
      } else return val;
    },
  },
  watch: {
    timeRangeType: function (value, oldValue) {
      if (value == 0) {
        // 0 = เดือนปัจจุบัน
        this.filter.start_date = moment().startOf('month').format('YYYY-MM-DD');
        this.filter.end_date = moment().endOf('month').format('YYYY-MM-DD');
      } else if (value == 1) {
        // 1 = เลือกช่วงวันที่
        this.filter.start_date = moment().startOf('month').format('YYYY-MM-DD');
        this.filter.end_date = moment().endOf('month').format('YYYY-MM-DD');
      } else if (value == 2) {
        // 2 = เดือนก่อน
        this.filter.start_date = moment()
          .subtract(1, 'months')
          .startOf('month')
          .format('YYYY-MM-DD');
        this.filter.end_date = moment()
          .subtract(1, 'months')
          .endOf('month')
          .format('YYYY-MM-DD');
        this.month = moment().subtract(1, 'months').format('YYYY-MM');
      } else if (value == 3) {
        // 3 = วันนี้
        this.filter.start_date = moment().format('YYYY-MM-DD');
        this.filter.end_date = this.filter.start_date;
      }
    },
  },
};
</script>
<style lang="css" scoped>
/* .row-pointer >>> tbody tr :hover {
  cursor: pointer;
} */
</style>
